export const fileTypes = ["PAS", "HCP"];
const NWUNIT = 5668
const getUnit =(val)=> {
  return (val/NWUNIT).toFixed(2)
}
export const prorityValues = [
  { label: `P1 - > $4K`, value: 1,nwunits:`P1 - > ${getUnit(4000)}NWAU` },
  { label: `P2 - $1.5K`, value: 2,nwunits:`P2 - ${getUnit(1500)}NWAU` },
  { label: `P3 - < $1.5K`, value: 3, nwunits:`P3 - < ${getUnit(1500)}NWAU`},
  { label: `P4- Quality Check Only`, value: 4},
  { label: `P5- Passed`, value: 5 },
];

export const statusTypes = [
  "Failed",
  "Not Started",
  "Flagged for Review",
  "Auditing in Progress",
  "Reviewed,no change",
  "Reviewed,query required",
  "Reviewed,query sent",
  "Reviewed,recoded",
  "Reviewed,rebilled",
  "Reviewed,reclaimed"
];

