import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import Logo from "../../common/assets/images/imdexlogo.png";
import axios from "axios";
import { BiLoader } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { CircleImg } from "../../common/assets/svg/CircleImg";
import { ArrowDown } from "../../common/assets/svg/ArrowDown";
import { Link } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";
import notifications from "./notificationsdummy.js";
import { useNavigate } from "react-router-dom";
import settings_icom from "../../../src/assets/settings.svg";
import feedback_icon from "../../../src/assets/person-feedback-svgrepo-com.svg";
import userfeedbacks_icon from "../../../src/assets/userfeedbacks.svg";
import logout_icon from "../../../src/assets/logout.svg";
import { configData, adminConfigData } from "./config";
import admin_icon from "../../../src/assets/admin.svg";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown.js";

function Navbar({
  setMode,
  mode,
  selectedCompanion,
  style,
  setSelectedCompanionState,
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { userEmail } = useAuth();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const adminUser = permissions && permissions.admin;
  const [navConfigData, setNavConfigData] = useState(configData);
  const [showAdminConfigData, setShowAdminConfigData] = useState(false);
  const location = useLocation();

  let adminRoutes = [
    "/users",
    "/role-management",
    "/module-management",
    "/role-module-permission",
    "/user-role-assignment",
    "/manage-organization",
    "/manage-countries",
    "/allusers",

  ];

  useEffect(() => {
    if (adminRoutes.includes(location.pathname)) {
      setShowAdminConfigData(true);
      // document.querySelector(".selected-companion").innerText = "Users"
    }
  }, []);

  useEffect(() => {
    const userPermissions = Object.entries(permissions).reduce(
      (acc, [key, value]) => {
        if ((typeof value === "boolean" && value) || Array) {
          acc.push(key);
        }
        return acc;
      },
      []
    );
    // console.log(userPermissions,"=============================================================================" ,typeof(userPermissions));

    const isAdmin = permissions.admin === true;
    // const filteredConfigData = configData.filter(item => {
    //   return userPermissions.includes(item.name);

    // });
    const filteredConfigData = isAdmin
      ? configData // If admin, include all config data
      : configData.filter(
          (item) =>
            userPermissions.includes(item.name) ||
            item.name === "DiscoverFeatures"
        );

    // console.log(filteredConfigData);

    // console.log(filteredConfigData);
    if (showAdminConfigData) {
      setNavConfigData(adminConfigData);
    } else {
      setNavConfigData(filteredConfigData);
    }
  }, [showAdminConfigData]);

  const handleLogoutClick = () => {
    setLoading(true);
    const postapiEndpoint = "";
    const accessToken = sessionStorage.getItem("accessToken");
    const postpayload = {
      body: {
        session_token: accessToken,
      },
    };
    // Check if an API endpoint exists (future use case)
    if (postapiEndpoint) {
      // Perform API-based logout
      axios
        .post(postapiEndpoint, postpayload)
        .then((response) => {
          //console.log("Logout request successful:", response);

          // Clear sessionStorage and handle logout
          sessionStorage.clear();
          window.location.reload(); // Or navigate to the login pag
        })
        .catch((error) => {
          //console.log("Error in logout request:", error);
          setError(error.message);
        })
        .finally(() => {
          setLoading(false); // Stop loading spinner
        });
    } else {
      // Manual logout (no API endpoint available)
      //console.log("Manual logout");

      // Clear sessionStorage manually
      sessionStorage.clear();

      // // Update state and reload the app or navigate to the login page
      window.location.reload(); // Optionally use `navigate("/login")` if preferred

      setLoading(false);
    }
  };

  const handleCompanionClick = (companion) => {
    setSelectedCompanionState(companion);
    sessionStorage.setItem("companion", companion);
  };
  const handleMode = () => {
    const newMode = mode === "DARK" ? "LIGHT" : "DARK";
    setMode(newMode);
    sessionStorage.setItem("mode", newMode);
  };


  useEffect(() => {
    const storedCompanion = sessionStorage.getItem("companion");
    //console.log(storedCompanion,"=============================================");
    if (storedCompanion) {
      setSelectedCompanionState(storedCompanion);
    }
    //console.log(selectedCompanion,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
  }, []);
  const handleAdminSettingsClick = () => {
    const newCompanion = showAdminConfigData ? "Discover Features" : "Users";
    sessionStorage.setItem("companion", newCompanion);
    setSelectedCompanionState(newCompanion); // Set new companion based on the current toggle state
    setShowAdminConfigData(!showAdminConfigData);
  };
  return (
    <div className="landingpage-header" style={style}>
      <div className="landing-header-top">
        <img className="landing-logo" src={Logo} alt="fff" />
        <div className="landing-account-info">
         {/* <NotificationDropdown/> */}
          <div className="d-flex">
            <span className="mode-color-nav">
              {mode === "DARK" ? "Light Mode" : "Dark Mode"}
            </span>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={mode === "DARK"}
                  onChange={handleMode}
                />
                <span className="slider round light"></span>
              </label>
            </div>
          </div>
          <span className="landingpage-user-email">{userEmail}</span>
          <CircleImg />
          <div className="landingpage-arrow-down">
            <ArrowDown />
            <div className="landingpage-dropdown-content">
              <div>
                <div style={{ padding: "8px 8px 0px 12px" }}>
                {(permissions?.["AuditRuleSettings"]?.length > 0 || 
                  (!permissions?.["super_user"] && !permissions?.["super_user_readonly"])) && (
                  <Link
                    to="powerapp"
                    onClick={() => handleCompanionClick("Audit Rule Settings")}
                  >
                    <img
                      src={settings_icom}
                      alt="settings"
                      style={{ height: "22px" }}
                    />
                    <span className="pb-2">Audit Rule Settings</span>
                  </Link>
                  )}
                </div>
                <div style={{ padding: "8px 8px 0px 12px" }}>
                  <Link
                    to="feedback"
                    onClick={() => handleCompanionClick("Feedback")}
                  >
                    <img
                      src={feedback_icon}
                      alt="feedback"
                      style={{ height: "22px" }}
                    />
                    <span className="pb-2">Feedback</span>
                  </Link>
                </div>
                <div style={{ padding: "8px 8px 0px 12px" }}>
                  <Link
                    to="user-feedbacks"
                    onClick={() => handleCompanionClick("User Feedback")}
                  >
                    <img
                      src={userfeedbacks_icon}
                      alt="userfeedbacks"
                      style={{ height: "22px" }}
                    />
                    <span className="pb-2">User Feedback</span>
                  </Link>
                </div>
                {adminUser && (
                  <div style={{ padding: "8px 8px 0px 12px" }}>
                    <Link
                      to={showAdminConfigData ? "discover-features" : "users"}
                      onClick={handleAdminSettingsClick}
                    >
                      <img
                        src={admin_icon}
                        alt="admin"
                        style={{ height: "22px" }}
                      />
                      <span>
                        {showAdminConfigData
                          ? "User Settings"
                          : "Admin Settings"}
                      </span>
                    </Link>
                  </div>
                )}
                <div onClick={handleLogoutClick}>
                  <span className="landing-logout-btn">
                    <img
                      src={logout_icon}
                      alt="logout"
                      style={{ height: "25px" }}
                    />
                    {loading ? <BiLoader /> : "Logout"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-header-bottom">
        <div className="selected-companion">{selectedCompanion}</div>

        <ul className="landing-companion-toggle-btn">
          {navConfigData.map((route, index) => (
            <li
              key={index}
              // className={selectedCompanion === route.header ? "active" : ""}
              onClick={() => handleCompanionClick(route.header)}
            >
              <NavLink to={route.path}>{route.header}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
