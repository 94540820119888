import axios from "axios";
import axiosInstance from "../../../../axiosInterceptor";

export const getS3Url = async (folderName, fileName) => {
    const url =
      "https://2pwerrxrwc.execute-api.ap-southeast-2.amazonaws.com/feedback-form-signed-ics_v1";
    return await axiosInstance
      .get(`${url}?folder_name=${folderName}&file_name=${fileName}`)
      .then((res) => res.data.signed_url)
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const fileUpload = async (s3Url, formData) => {
    return await axios
      .post(s3Url, formData, {
        headers: "multipart/form-data",
      })
      .then((res) => res)
      
      .catch((err) => {
        throw new Error(err.message);
      });
  };