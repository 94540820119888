
export const topSideSections = [
  {
    title: "Episode failed multiple rules",
    key: "episode_failed_multiple_rules",
  },
  { title: "Episode identifier", key: "episode_identifier" },
  { title: "Medical record number", key: "medical_record_number" },
  { title: "Predicted drg", key: "predicted_drg" },
  { title: "Current drg revenue", key: "current_drg_revenue" },
  { title: "Predicted drg revenue", key: "predicted_drg_revenue" },
  { title: "Hospital type", key: "hospital_type" },
  { title: "Drg code", key: "drg_code" },
  { title: "Care type", key: "care_type" },
  { title: "Principal diagnosis code", key: "principal_diagnosis_code" },
  { title: "Additional diagnosis", key: "additional_diagnoses1" },
  { title: "Procedure codes", key: "procedure_codes1" },
  { title: "Sameday status", key: "sameday_status" },
  { title: "Principal mbs item number", key: "principal_mbs_item_number" },
  { title: "Prosthesis charge", key: "prosthesis_charge" },
  { title: "ar drg version", key: "ar_drg_version" },
  { title: "Audit drg", key: "audit_drg" },
  { title: "Audit drg revenue", key: "audit_drg_revenue" },
  { title: "Previous drg 1", key: "previous_drg_1" },
  { title: "Previous drg 2", key: "previous_drg_2" },
  { title: "Previous drg 3", key: "previous_drg_3" },
];
export const bottomSideSections = [
  { title: "Category", key: "audit_rule_category"},
  { title: "Ref no", key: "audit_rule_ref_no"},
  {title:"Priority", key:"priority_value"},
  {title:"Short Description" ,key:"audit_rule_short_description"},
  {title:"Long description",key:"audit_rule_long_description"},
];

const codes = (key, title, len) => Array(len).fill(1).map((_, i) => ({key: `${key}${i+1}`, title: `${title}${i+1}`}))
export const procedureCodesFields = codes("procedure_codes", "Procedure codes", 50)
export const additionalDiagnosesFields = codes("additional_diagnoses", "Additional diagnosis", 49)