import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInterceptor";
import "./users.css";
import { useChatContext } from '../../../context/index.js';


const AllUsers = () => {
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const { common } = useChatContext(); 
    const { selectedCompanion, setSelectedCompanionState } = common;
       const [isLoading, setIsLoading] = useState(true); 

    useEffect(()=>{
        setIsLoading(true);
        axiosInstance.get("https://nmy51dqgk8.execute-api.ap-southeast-2.amazonaws.com/get_admin_users")
        .then((response) => {
            setAllUsers(response.data.data)  
            setIsLoading(false);
        }
    )
        .catch((error) => {
                    toast.error(error.response.data)
                    //console.log("error",error.response.data);
                     setIsLoading(false);
                });
    },[]);
    const handleUsersBack = () => {
      const companion = "Users"; // This should be the companion you're updating to
      sessionStorage.setItem("companion", companion);
      setSelectedCompanionState(companion);
      navigate("/users");
    };

  return (
    <div className=' mt-2 user-management'>
        <div className='my-3 '
        style={{textDecoration: "underline", cursor: "pointer"}}
        >
        <span
          onClick={handleUsersBack}
        >
          Users
        </span>{" "}
        {">"}
          <span style={{ textDecoration: "none", cursor: "default", marginLeft: "10px" }}>
            Approved Users
           </span>
        </div>
        <div style={{ maxHeight: "600px", overflowY: "auto",scrollbarWidth: "thin",}}>
        <table className="usermangement">  
        <thead >
            <tr>
            <th scope="col">Username</th>
            <th scope="col">Organization</th> 
            <th scope="col">Country</th>
            <th scope="col">Role</th>
            <th scope="col">Email</th>
            </tr>
        </thead>
        <tbody>
         {isLoading ? (
                     <p>Loading ...</p>
                       ) :
         allUsers && allUsers.length > 0 ? (
            allUsers.map((user,index) =>(
                <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user.organization_name}</td>
                    <td>{user.country_name}</td>
                    <td>{user.role}</td>
                    <td>{user.email}</td>
                    
                </tr>
            ))
        ) : ( <p>No data available</p>
        )}
        </tbody>
        </table>
        </div>
    </div>
  )
}

export default AllUsers