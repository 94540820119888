
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInterceptor";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RoleModulePermissions.css";

const RoleModulePermissions = () => {
  const [dbModules, setDbModules] = useState([]);
  const [dbRoles, setDbRoles] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [accessType, setAccessType] = useState("");
  const [savedPermissions, setSavedPermissions] = useState([]);

  // Fetch initial data
  useEffect(() => {
    // Fetch modules
    axiosInstance
      .get(
        "https://vhts43qur4.execute-api.ap-southeast-2.amazonaws.com/get_admin_modules/"
      )
      .then((response) => setDbModules(response.data.data))
      .catch((err) => console.error("Error fetching modules: ", err));

    // Fetch roles
    axiosInstance
      .get(
        "https://5rd23r99of.execute-api.ap-southeast-2.amazonaws.com/get_admin_roles/"
      )
      .then((response) => {
        const unwantedRoles = [
          "admin",
          "System Administrator",
          "Super user",
          "Privileged User",
          "Super User Readonly",
        ];

        const filteredRoles = response.data.data.filter(
          (role) =>
            !unwantedRoles.some(
              (unwanted) =>
                role.role_name.trim().toLowerCase() === unwanted.toLowerCase()
            )
        );

        setDbRoles(filteredRoles);
      })
      .catch((err) => console.error("Error fetching roles: ", err));

    // Fetch saved permissions
    fetchPermissions();
  }, []);

  // Fetch saved permissions
  const fetchPermissions = () => {
    axiosInstance
      .get(
        "https://hbpw30xbcf.execute-api.ap-southeast-2.amazonaws.com/admin_role_module_permissions/"
      )
      .then((response) => {
        setSavedPermissions(response.data.data);
      })
      .catch((err) => console.error("Error fetching permissions: ", err));
  };

  // Handle dropdown changes
  const handleDropdownChange = (type, value) => {
    if (type === "module") {
      setSelectedModule(value);
    } else if (type === "role") {
      setSelectedRole(value);
    }
  };

  // Handle access type changes
  const handleAccessTypeChange = (type) => {
    setAccessType(type === accessType ? "" : type); // Toggle the same type
  };

  // Save permissions
  const handleSavePermissions = () => {
    if (!selectedModule || !selectedRole) {
      toast.error("Module and Role cannot be empty.");
      return;
    }

    if (!accessType) {
      toast.error("Please select an access type.");
      return;
    }

    const payload = {
      moduleId: parseInt(selectedModule),
      roleId: parseInt(selectedRole),
      accessType,
      createdBy: sessionStorage.userId,
    };

    axiosInstance
      .post(
        "https://3upy7ib1d8.execute-api.ap-southeast-2.amazonaws.com/post_admin_role_module_permissions/",
        payload
      )
      .then(() => {
        toast.success("Permissions saved successfully!");
        fetchPermissions(); // Refresh table data
        // Clear the form fields after saving
        setSelectedModule("");
        setSelectedRole("");
        setAccessType("");
      })
      .catch((err) => {
        console.error("Error saving permissions: ", err);
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;
          if (errorMessage.includes("duplicate key value")) {
            toast.error(
              "This role and module combination with the selected access type already exists."
            );
          } else {
            toast.error("Failed to save permissions. Please try again.");
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="role-module-permissions-container">
      <ToastContainer />
      <div className="form-group-rolemodule">
        <div className="permissions-row">
          <select
            className="form-control-rolemodule"
            value={selectedModule}
            onChange={(e) => handleDropdownChange("module", e.target.value)}
          >
            <option value="">Select Module</option>
            {dbModules.map((module) => (
              <option key={module.module_id} value={module.module_id}>
                {module.module_name}
              </option>
            ))}
          </select>

          <select
            className="form-control-rolemodule"
            value={selectedRole}
            onChange={(e) => handleDropdownChange("role", e.target.value)}
          >
            <option value="">Select Role</option>
            {dbRoles
              .filter((role) => {
                const isRoleUsed = savedPermissions.some(
                  (perm) => perm.role_id === role.role_id
                );
                return !isRoleUsed;
              })
              .map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </option>
              ))}
          </select>

          <label>
            <input
              type="checkbox"
              checked={accessType === "read"}
              onChange={() => handleAccessTypeChange("read")}
            />{" "}
            Read
          </label>

          <label>
            <input
              type="checkbox"
              checked={accessType === "fullAccess"}
              onChange={() => handleAccessTypeChange("fullAccess")}
            />{" "}
            Full Access
          </label>

          <button
            className="btn-save-permission"
            onClick={handleSavePermissions}
          >
            Save Permission
          </button>
        </div>
      </div>

      <table className="table role-permissions-table">
        <thead>
          <tr>
            <th>Module Name</th>
            <th>Role Name</th>
            <th>Read</th>
            <th>Full Access</th>
          </tr>
        </thead>
        <tbody>
          {savedPermissions.map((perm, index) => (
            <tr key={index}>
              <td>{perm.module_name}</td>
              <td>{perm.role_name}</td>
              <td>{perm.access_type === "read" ? "Yes" : "No"}</td>
              <td>{perm.access_type === "fullAccess" ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoleModulePermissions;


