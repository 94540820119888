import React, { useEffect, useRef, useState } from "react";
import "./ChatPage.css";
import CompanionLogo from "../../assets/comapionlogo.png";
import SendIcon from "../../assets/send.png";
import chatIcon from "../../assets/chaticon.png";
import StopGenerating from "../../assets/stopgeneratingicon.png";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import axios from "axios";
import MessageCard from "./Message";
import InitialMessageSection from "./InitialMessageSection";
import { Loader } from "../../utils/util";
import {
  fetchArchiveChat,
  getChatConversation,
  getConvo,
  updateMessageLikeStatus,
} from "../../api/Service";
import { useChatContext } from "../../../../../context";
import { Spinner } from "../../../web-workflow/common/Helper";
import MessageLoader from "./MessageLoader";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import axiosInstance from "../../../../../axiosInterceptor";
import ChatVersions from "./ChatVersions";
import PromptTemplate from "./PromptTemplate";

function ChatPage() {
  const messagesRef = useRef(null);
  const { common, chatAI, scroll } = useChatContext();
  const [versionID,setVersionId]=useState(1);
  const {
    conversations,
    setConversations,
    activeConversation,
    setActiveConversation,
    messages,
    setMessages,
    selectedModal,
    setSelectedModal,
    isFirstMesssge,
    setIsFirstMessage,
    archivedChats,
    setArchivedChats,
    isMessagesLoading,
    setIsMessagesLoading,
  } = chatAI;
  const { mode } = common;
  const {
    isConversationsLoading,
    setIsConversationsLoading,
    hasMoreConversations,
    setHasMoreConversations,
    currentPage,
    setCurrentPage,
  } = scroll;

  const [isOpen, setIsOpen] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [error, setError] = useState("");
  const [stopGenerating, setStopGenerating] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [isPrevMessagesLoading, setIsPrevMessagesLoading] = useState(false);
  const [hasPrevMessages, setHasPrevMessages] = useState(true);
  const [prevMszPageNumber, setPrevMszPageNumber] = useState(1);
  const [finalTotalText,setFinalTotalText] = useState("")
 const inifinteWrapperClassName = ".chat-infinte-scroll";
  
  useEffect(()=>{
   const matchingVersion=ChatVersions.find(eachVersion=>eachVersion.version===selectedModal);
   if(matchingVersion && matchingVersion.version_id!==versionID){
    setVersionId(matchingVersion.version_id)
   }

  },[selectedModal,versionID])
  //console.log(versionID,'all version id');
  const scrollToBottomFn = (prevHeight = 0) => {
    setTimeout(() => {
      const el = document.querySelector(inifinteWrapperClassName);
      if (el) {
        const { scrollHeight } = el;
        el.scrollTop = scrollHeight - prevHeight;
      }
    }, 0);
  };

  const getConversations = async (pageNum, loadMore) => {
    try {
      setIsConversationsLoading(true);
      const messages = await getConvo(pageNum);
      if (loadMore) {
        setConversations([...conversations, ...messages]);
      } else {
        setConversations(messages);
      }
      setHasMoreConversations(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      return messages;
    } catch (err) {
     //console.log("get_convo_error:::", err.message);
      setHasMoreConversations(false);
      if (!loadMore) {
        setConversations([]);
      }
    } finally {
      setIsConversationsLoading(false);
    }
  };

  useEffect(() => {
    getConversations(1);
  }, []);

  const handleMessageInputChange = (event) => {
    const newValue = event.target.value;
    
    if (newValue.length === 0) {
      setFinalTotalText("")
    }
  
    setMessageInput(newValue);
  };

  useEffect(()=> {
    if(finalTotalText.length > 0) {
    setMessageInput(finalTotalText)
    }
  }, [finalTotalText])

  const uploadConversationMessage = async (payload) => {
    const {conversation_id, newMessage } = payload;
    const endPoint =
      "https://qev2c0drm8.execute-api.ap-southeast-2.amazonaws.com/llm_post_messages_ics_v1";
    axiosInstance
      .post(endPoint, {
          conversation_id,
          message_sent: newMessage.question,
          answer_received: {
            answer: newMessage.answer.answer,
            contents_list: newMessage.answer.contents_list,
          },
      })
      .then((data) => {
        //console.log("CONVO UPLOADED SUCCES::", data, messages);
        const messageRes = data.data;
        setMessages([
          ...messages,
          { ...newMessage, message_id: messageRes.prompt_id },
        ]);
        scrollToBottomFn(0)
      })
      .catch((err) => {
        console.log("CONVO UPLOAD FAIL:::", err.message);
      });
  };
  const sendMessage = (input) => {
    const question = input ? input : messageInput;
    if (!question) return;
    setStopGenerating(true);
    const controller = new AbortController();
    setAbortController(controller);
    const accessToken = sessionStorage.getItem("useremail");
    const newMessage = { question, response: null };
    setMessages([...messages, newMessage]);
    setIsMessageLoading(true);
    setMessageInput("");
    const token = sessionStorage.getItem('accessToken'); 
    const url =
      selectedModal === "VERSION_1"
        ? "https://qi79lwqmtb.execute-api.us-west-2.amazonaws.com/pallas-ai-gen-ai-lambda-func-v4/"
        : "https://39o5scb2l3.execute-api.us-west-2.amazonaws.com/lambdaAWSQV2/";
        scrollToBottomFn(0)

    fetch(url, {
      method: "POST",
      headers: {
        'authorizationToken':`${token}`,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ query: newMessage.question }),
      body: JSON.stringify({ prompt: newMessage.question }),
      signal: controller.signal,
    })
      .then((response) => {
        //console.log(response, "response131::");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log("Message sent successfully:", data);
        // const responseBody = JSON.parse(data.body);
        // console.log(responseBody, "::0909");
        // const message = responseBody.meesage;
        // newMessage.answer = message;
        // Check which URL was used

        const responseBody = JSON.parse(data.body);
        //console.log(responseBody, "146::");
        if (
          url ===
          "https://39o5scb2l3.execute-api.us-west-2.amazonaws.com/lambdaAWSQV2/"
        ) {
          // Handle specific new structure with multiple chunks of data
          const meesage = responseBody.meesage;
          // console.log('finalv2mess',meesage);
          let message = "";
          if (meesage) {
            for (const key in meesage) {
              if (meesage[key].answer) {
                message += meesage[key].answer + " ";
              }
            }
            message = message.trim() || "No message found";
          } else {
            message = "No message found";
          }
          newMessage.answer = {
            answer: responseBody.meesage,
          };
        } else {
          const responseBody = JSON.parse(data.body);
          const message = responseBody.message;
          newMessage.answer = {
            answer: message,
          };
        }
        newMessage.answer.contents_list = responseBody.contents_list;
        if (isFirstMesssge) {
          getConversations()
            .then((data) => {
              //console.log("SEND_COVO::", data, newMessage);
              const conv = data?.find(
                (conv) => conv.conversation === newMessage.question
              );
              //console.log("CONV",conv,newMessage,conv.conversation,newMessage.question);
              if (conv.conversation_id) {
                setActiveConversation(conv);
                uploadConversationMessage({
                  conversation_id: conv.conversation_id,
                  newMessage,
                });
              }
            })
            .catch((err) => {
              console.log("SEND_CONV_ERR::", err.message);
            });
        } else {
          uploadConversationMessage({
            conversation_id: activeConversation.conversation_id,
            newMessage,
          });
        }
        getConversations()
          .then((data) => {
            const conv = data.find(
              (conv) => conv.conversation === newMessage.input
            );
            //console.log("SEND_COVO::", conv, data, newMessage);
          })
          .catch((err) => console.log("SEND_CONV_ERR::", err.message));
        // console.log(message, "5252::");
      })
      .catch((error) => {
        if(error.response && (error.response.status===401 || error.response.status===403)){
          let errorMessage='';
          if(error.response.status===401){
            errorMessage='Please login again'
          }else if(error.response.status===403){
            errorMessage='You do not have permission to access this resource'
          }
          window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
        }
       //console.log("There was a problem sending the message:", error);
        setMessages([...messages]);
      })
      .finally(() => {
        setIsMessageLoading(false);

        setStopGenerating(false);
      });
    //console.log("IS_FIRST:::", isFirstMesssge);
    if (isFirstMesssge) {
      const postpayload = {
        
          conversation: question,
          version_id:versionID
        
      };

      const postapiEndpoint =
        "https://a46fv555ua.execute-api.ap-southeast-2.amazonaws.com/llm_post_conversation_ics_v1";
      axiosInstance
        .post(postapiEndpoint, postpayload, { signal: controller.signal })
        .then((response) => {
          //console.log("POST request successful:", response.data);
          const { data } = response;
          if (data.errorMessage) {
            throw new Error(data.errorMessage);
          }
        })
        .catch((error) => {
         //console.log("Error in POST request:", error);
          setError(error.message);
        });

      setIsFirstMessage(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const getConversationMessages = async (
    id,
    page_number = 1,
    isUpate = false
  ) => {
    let response;
    try {
      if (isUpate) {
        setIsPrevMessagesLoading(true);
      } else {
        setIsMessagesLoading(true);
      }
      if(id){
        response=await getChatConversation(id, page_number);
      }
      //console.log("response::::", response);
      const { conversations, isVersion2, error } = response;
      if (error) {
        setHasPrevMessages(false);
        return;
      }
      if (isVersion2) {
        setSelectedModal("VERSION_2");
      } else {
        setSelectedModal("VERSION_1");
      }
      if (isUpate) {
        const chats = conversations.reverse();
        setMessages([...chats, ...messages]);
        const el = document.querySelector(inifinteWrapperClassName);
        scrollToBottomFn(el.scrollHeight);
      } else {
        setMessages(conversations.reverse());
        scrollToBottomFn(0);
      }
      setHasPrevMessages(true);
      setIsFirstMessage(false);
    } catch (err) {
      setHasPrevMessages(false);
      //console.log("CONVO_MESSAGES_ERR::", err);
    } finally {
      setIsMessagesLoading(false);
      setIsPrevMessagesLoading(false);
    }
  };
  const handleConversationClick = (convo) => {
    setActiveConversation(convo);
    getConversationMessages(convo.conversation_id);
  };
  const handleNewChatBtnClick = () => {
    setActiveConversation({});
    setIsFirstMessage(true);
    setMessages([]);
  };
  const handleCoderCompanionGptClick = () => {
    setActiveConversation({});
    setIsFirstMessage(true);
    setMessages([]);
    setSelectedModal("VERSION_2");
  };
  const handleCoderCompanionClick = () => {
    setActiveConversation({});
    setIsFirstMessage(true);
    setMessages([]);
    setSelectedModal("VERSION_1");
  };
  const handleStopGenerating = () => {
    abortController.abort();
    const newMessages = messages.filter(
      (message) => message.question !== messageInput
    );
    setMessages(newMessages);
    setStopGenerating(false);
  };
  const handleMessageClick = (title) => {
    setMessageInput(title);
    sendMessage(title);
  };

  const fetchArchivedConversations = async () => {
    try {
      const response = await fetchArchiveChat();
      setArchivedChats(response);
    } catch (err) {
     console.log("Error in Archived request:", err.message);
    }
  };
  const updateLikeState = ({ session_token, message_id, is_liked,user_comment_on_message_response}) => { 
    updateMessageLikeStatus({ session_token, message_id, is_liked,user_comment_on_message_response}).then(
      () => {
        const updateMessages = messages.map((message) => {
          if (message.message_id === message_id) {
            return { ...message, is_liked };
          }
          return message;
        });
        setMessages(updateMessages);
        
      }
    );
  };
  const getPrevMessages = () => {
    setPrevMszPageNumber((prev) => prev + 1);
    getConversationMessages(
      activeConversation.conversation_id,
      prevMszPageNumber + 1,
      true
    );
  };
  return (
    <>
      <PromptTemplate setFinalTotalText={setFinalTotalText}/>

     

      <div
        className={`main-page-container ${
          mode === "DARK" ? "dark-container" : " light-container"
        }`}
      >
        {mode === "DARK" && (
          <Sidebar
            conversations={conversations}
            handleClick={handleConversationClick}
            handleNewChatBtnClick={handleNewChatBtnClick}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            getConversationsFn={getConversations}
            fetchArchivedConversations={fetchArchivedConversations}
            setMessages={setMessages}
            isLoading={isConversationsLoading}
            hasMoreConversationsVal={hasMoreConversations}
            currentPageNum={currentPage}
            versionID={versionID}
            setIsFirstMessage={setIsFirstMessage}
            setActiveConversation={setActiveConversation}
          />
        )}
        <div className="page-wrapper">
          <div className="chat-space-wrapper">
            <div className="coder-toggle-button">
              <span
                className={selectedModal === "VERSION_1" ? "active" : ""}
                onClick={handleCoderCompanionClick}
              >
                Ai Companion V1
              </span>
              <span
                className={selectedModal === "VERSION_2" ? "active" : ""}
                onClick={handleCoderCompanionGptClick}
              >
                Ai Companion V2
              </span>
            </div>
            <Header
        setError={setError}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getConservations={getConversations}
        archivedChats={archivedChats}
        fetchArchivedConversations={fetchArchivedConversations}
      />
            {/* <div className="gptLogo">
              <img src={CompanionLogo} alt="CompanionLogo" />
            </div> */}
          </div>
          <div className="intial-message-card-wrapper">
            <div
              className={`message-wrapper ${
                messages.length === 0 ? "initial-messages" : ""
              }`}
              ref={messagesRef}
            >
              {isMessagesLoading ? (
                <Spinner />
              ) : (
                <>
                {messages.length === 0 && (
                  <InitialMessageSection
                    handleMessageClick={handleMessageClick}
                  />
                )}
                <InfiniteScroll
                  isTopScroll={true}
                  className="message-wrapper chat-infinte-scroll"
                  loadMore={() => getPrevMessages()}
                  hasData={hasPrevMessages}
                  isLoading={isPrevMessagesLoading}
                >
                  {messages.map((message) => (
                    <MessageCard
                      key={message.message_id}
                      message={message}
                      updateLikeState={updateLikeState}
                    />
                  ))}
                  {isMessageLoading && <MessageLoader />}
                </InfiniteScroll>
                
              </>
              )}

              <div className="search-wrapper">
                {stopGenerating && (
                  <p onClick={handleStopGenerating} className="stop-generating">
                    <img src={StopGenerating} alt="CompanionLogo" />{" "}
                    <span>Stop Generating</span>
                  </p>
                )}
                <div>
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Message AI Companion"
                    value={messageInput}
                    onChange={handleMessageInputChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="input-button" onClick={() => sendMessage()}>
                  <img src={SendIcon} alt="sendicon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatPage;
