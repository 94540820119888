import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../feedback/UserFeedbacks.css";
import { Spinner } from "../web-workflow/common/Helper";
import download_icon from "../codercompanion/assets/svg/download.svg";
import cross_icon from "../codercompanion/assets/svg/cross-icon.svg";
import { BiLoader } from "react-icons/bi";
import axiosInstance from "../../../axiosInterceptor";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const UserFeedbacks = () => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  
  const [usersFeedbackData, setUsersFeedbackData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState({id : undefined , status: false});
  const tableRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const allUserFeedbackData = async (page) => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    const url =
      "https://jd32e2ossj.execute-api.ap-southeast-2.amazonaws.com/user_feedback_get_details_api_ics_v1";
    try {
      const response = await axiosInstance.get(url, {
        params: {
          pageNumber: page,
        },
      });
      const newItems = response.data;
      if(newItems.message==="No More data"){
        setHasMore(false);
      }else{
      setUsersFeedbackData([...usersFeedbackData,...newItems]);
      if(newItems.length<30){
        setHasMore(false);
      }
    }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownloadFile = async (fileName,id) => {
    
    setIsLoading2({id,status:true});
    const apiEndpoint =
      "https://7o2lkevt99.execute-api.ap-southeast-2.amazonaws.com/download_user_feedback_attachment_ics_v1";
    try {
      const response = await axiosInstance.get(apiEndpoint, {
        params: {
          file_name: fileName,
        },
      });
      const { url } = response.data;
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "test";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setIsLoading2({id,status:false})
    } catch (error) {
      throw new Error(error.message);
    }
  };
  useEffect(() => {
    if(hasMore){
      allUserFeedbackData(pageNumber);
    }
  }, [pageNumber,hasMore]);
  const handleTableScroll = () => {
    // console.log('hyffhgvh');
    
    const tableVal = tableRef.current;
    if (
      tableVal.scrollTop + tableVal.clientHeight >=
      tableVal.scrollHeight - 1
    ) {
      if (!isLoading && hasMore) {
        setPageNumber(pageNumber+1);
      }
    }
  };
  useEffect(() => {
    if (pageNumber > 1) {
      allUserFeedbackData(pageNumber);
    }
  }, [pageNumber]);
  useEffect(() => {
    const tableNewVal = tableRef.current;
    if(tableNewVal){
    tableNewVal.addEventListener("scroll", handleTableScroll);
    }
    return () => tableNewVal.removeEventListener("scroll", handleTableScroll);
  }, [isLoading]);
  return (
    <div className="mt-5">
      <div
        className="userfeedback-wrapper"
        ref={tableRef}
        style={{ maxHeight: "706px", overflow: "auto" }}
      >
        {usersFeedbackData.length===0 && <div style={{color:'red',textAlign:'center'}} className="mb-1">{errorMessage}</div>}
        {usersFeedbackData.length > 0 ? (
          <><table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Date Of Submission</th>
                <th>Browser or App version</th>
                <th>Feedback Type</th>
                <th>FeedBack description</th>
                <th>User Satisfaction Rating</th>
                <th>Feature Request Title</th>
                <th>Enhancement Description</th>
                <th>Importance</th>
                <th>Business Impact</th>
                <th>Bug Title</th>
                <th>Bug Description</th>
                <th>Expected Behaviour</th>
                <th>Actual Behaviour</th>
                <th>Severity</th>
                <th>Attachements</th>
                <th>Consent For FollowUp</th>
                <th>Submit Anonymously</th>
                <th>Consent to Terms</th>
              </tr>
            </thead>
            {usersFeedbackData.length > 0 && (
              <tbody>
                {usersFeedbackData.map((eachFeedback, id) => (
                  <tr key={id}>
                    {/* {eachFeedback.user_name === null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.user_name}</td>
                    )} */}
                    {eachFeedback.email === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.email}</td>
                    )}
                    {eachFeedback.date_of_submission === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.date_of_submission}</td>
                    )}
                    {eachFeedback.browser_or_app_version === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.browser_or_app_version}</td>
                    )}
                    {eachFeedback.feedback_type === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.feedback_type}</td>
                    )}
                    {eachFeedback.feedback_description === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.feedback_description}</td>
                    )}
                    {eachFeedback.user_satisfaction_rating === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.user_satisfaction_rating}</td>
                    )}
                    {eachFeedback.feature_request_title === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.feature_request_title}</td>
                    )}
                    {eachFeedback.enhancement_description === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.enhancement_description}</td>
                    )}
                    {eachFeedback.importance === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.importance}</td>
                    )}
                    {eachFeedback.business_impact === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.business_impact}</td>
                    )}
                    {eachFeedback.bug_title === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.bug_title}</td>
                    )}
                    {eachFeedback.bug_description === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.bug_description}</td>
                    )}
                    {eachFeedback.expected_behaviour === ""|| null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.expected_behaviour}</td>
                    )}
                    {eachFeedback.actual_behaviour === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.actual_behaviour}</td>
                    )}
                    {eachFeedback.severity === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.severity}</td>
                    )}
                    {eachFeedback.attachments === "" || null? (
                      <td>Not Found</td>
                    ) : (
                      <td>
                        {eachFeedback.attachments.length===0 ? (
                          <p>Not Found</p>
                        ) : (
                          eachFeedback.attachments.map((eachattachement,index=1) => {
                            return (
                              <div key={index}
                                className="d-flex gap-1"
                                onClick={() =>{
                                  if(eachattachement===""){
                                    toast.error("No File available for download")
                                  }else{
                                    handleDownloadFile(eachattachement,id+index)
                                  }
                                }}
                              >
                                {eachattachement==="" ?<div style={{whiteSpace:'nowrap'}}>No file Found</div>:<div className="userfeedback-btn pb-1">File{index+1}</div>}
                                {(isLoading2.id == id+index && isLoading2.status)?<BiLoader className="mt-1"/>:<img src={download_icon} />}
                              </div>
                            );
                          })
                        )}
                      </td>
                    )}
                    {eachFeedback.consent_follow_up === null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.consent_follow_up.toString()}</td>
                    )}
                    {eachFeedback.submit_anonymously === null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.submit_anonymously.toString()}</td>
                    )}
                    {eachFeedback.consent_terms === null ? (
                      <td>Not Found</td>
                    ) : (
                      <td>{eachFeedback.consent_terms.toString()}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {(!hasMore)&&<p style={{color:'red',fontSize:'14px',paddingLeft:'5px',paddingTop:'5px'}}>No more data</p>}
           </>
          ): (
          !isLoading && (
            <div className="d-flex flex-column gap-1">
              <img src={cross_icon} style={{ height: "32px" }} />
              <p style={{ color: "red" }} className="text-center">
                No Data Available
              </p>
            </div>
          )
        )}
        {isLoading && (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
export default UserFeedbacks;
