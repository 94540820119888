import React, { useState } from "react";
import axios from "axios"; // Import axios
import { forgotPassword, confirmPassword } from "../../authContext/CognitoAuth";
import "./ForgotPassword.css";
import { BiLoader } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword({ setShowLoginPage }) {
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const checkUserExists = async (username) => {
    try {
      const trimmedUsername = username.trim();

      const response = await axios.post('https://k37p0a5vdi.execute-api.ap-southeast-2.amazonaws.com/forgotPasswordApi_ics_v1', {
        username: trimmedUsername,
        userPoolId: 'ap-southeast-2_JmgDLheeH'
      });

      // const responseData = JSON.parse(response.data.body);
       // return responseData.is_user_exists;

    const responseMessage = response.data.message;
    toast.success(responseMessage);

       // Check if the message indicates user existence
      return responseMessage;
    } catch (error) {
     //console.log('Error checking user existence:', error);
     toast.error('Error checking user existence');
    //  throw new Error('Error checking user existence');
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const userExists = await checkUserExists(username);
      if (userExists) {
        await forgotPassword(username);
        setStage(2);
        setError("");
      } else {
        toast.error("User does not exist.");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPassword = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://et5dv8vzv5.execute-api.ap-southeast-2.amazonaws.com/confirmAndRestpassword_ics_v1', {
        username,
        confirmationCode: verificationCode,
        newPassword,
        
      });

      // await confirmPassword(username, verificationCode, newPassword);
      toast.success(response.data.message);
      setError("");
      setTimeout(() => {
        setShowLoginPage(true);
      }, 4000);
    } 
    catch (err) {
      if (err.response && err.response.data && err.response.data.details) {
        const errorDetails = err.response.data.details;
    
        // Dynamically handle specific error types
        if (errorDetails.includes("CodeMismatchException")) {
          toast.error("Invalid verification code provided. Please try again.");
        } else if (errorDetails.includes("InvalidPasswordException")) {
          // Extract and display only the specific policy-related message
          const policyMatch = errorDetails.match(/policy:(.+)/);
          if (policyMatch) {
            const policyMessage = policyMatch[1].trim();
            toast.error(policyMessage); // Show only the specific reason
          } else {
            toast.error("Password does not meet the required criteria. Please try again.");
          }
        } else if (errorDetails.includes("LimitExceededException")) {
          toast.error("Attempt limit exceeded. Please try again after some time.");
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        // Fallback error message for unexpected scenarios
        toast.error("An error occurred while resetting the password.");
      }
    }
     finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container-wrapper">
      <ToastContainer autoClose={20000} />
      <div className="forgot-password-container">
        {stage === 1 ? (
          <>
            <h2>Forgot Password</h2>
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setError("");  
                }}
              />
            </div>
            <button className="btn btn-warning" onClick={handleForgotPassword}>
              {loading ? <BiLoader /> : "Submit"}
            </button>
          </>
        ) : (
          <>
            <h2>Reset Password</h2>
            <div className="input-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                  setError("");
                }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setError("");
                }}
              />
            </div>
            <button className="btn btn-warning" onClick={handleConfirmPassword}>
              {loading ? <BiLoader /> : "Reset Password"}
            </button>
          </>
        )}
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <p className="forgotswitchLogin" onClick={() => setShowLoginPage(true)}>Back to Login</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
